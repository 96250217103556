import React, { memo, useEffect, useRef } from 'react';
import type { AnimationItem } from 'lottie-web';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { useScrollIntoView } from 'Hooks/useScrollIntoView';

import { WHY_HEADOUT_LIST_DATA } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const StyledContainer = styled.section`
	min-height: 8.25rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	gap: 1.5rem;
	overflow-x: auto;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
	padding: 2rem 1.5rem 2.625rem;

	@media (min-width: 768px) {
		min-height: 9.375rem;
		width: 100%;
		max-width: 75rem;
		margin: 0 auto;
		overflow-x: initial;
		padding: initial;
	}
`;

const StyledSectionItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 13rem;
	position: relative;
	overflow: visible;

	@media (min-width: 768px) {
		width: 17.625rem;
	}
`;
const StyledLogo = styled.div<{ $color: string }>`
	width: 2.25rem;
	height: 2.25rem;
	font-size: 1.125rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-bottom: 0.75rem;
	z-index: 1;

	@media (min-width: 768px) {
		width: 2.625rem;
		height: 2.625rem;
		font-size: 1.5rem;
		margin-bottom: 1rem;
	}
`;
const StyledTitle = styled.p`
	${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)}
	color: ${colors.GREY_DS.G2};
	margin-bottom: 0.25rem;
	z-index: 1;
	@media (min-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)}
	}
`;
const StyledText = styled.p`
	${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_REGULAR)}
	z-index: 1;

	@media (min-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_MEDIUM)}
	}
	color: ${colors.GREY_DS.G3};
`;
const StyledBgBlob = styled.div<{ $color: String }>`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	filter: blur(40px);
	opacity: 0.2;

	background: ${({ $color }) => `
        radial-gradient(
            5rem 5rem at 35% 35%,
            ${$color} 0%,
			${$color} 50%,
            rgba(255, 255, 255, 0) 50%
        ),
        radial-gradient(
            5rem 5rem at 20% 60%,
            ${$color} 0%,
			${$color} 50%,
			rgba(255, 255, 255, 0) 50%
		)`};
`;
const rootMargin = '0px 0px -50% 0px';
const WhyHeadoutSection = () => {
	const lottieWebAnimations = useRef([] as AnimationItem[]);
	const lottieContainerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!lottieContainerRef.current) return;
		const containerEle = lottieContainerRef.current;
		import('lottie-web').then(lottieWebInstance => {
			for (let i = 0; i < (containerEle as any).children.length; i++) {
				const animation = lottieWebInstance.default.loadAnimation({
					autoplay: false,
					loop: true,
					path: WHY_HEADOUT_LIST_DATA[i].url,
					container: (containerEle as any).children[i].firstChild,
				});
				lottieWebAnimations.current.push(animation);
			}
		});
	}, []);

	const playAnimation = (index: any) => () =>
		(lottieWebAnimations.current[index] as any)?.play();

	const stopAnimation = (index: any) => () =>
		(lottieWebAnimations.current[index] as any)?.stop();

	const globalPerks: { title: string; text: string }[] = [
		{
			title: strings.LESS_IS_MORE_TITLE,
			text: strings.LESS_IS_MORE_TEXT,
		},
		{
			title: strings.GREED_IS_GOOD_TITLE,
			text: strings.GREED_IS_GOOD_TEXT,
		},
		{
			title: strings.WE_DONT_JUDGE_TITLE,
			text: strings.WE_DONT_JUDGE_TEXT,
		},
		{
			title: strings.NO_PAIN_ONLY_GAIN_TITLE,
			text: strings.NO_PAIN_ONLY_GAIN_TEXT,
		},
	];

	return (
		<StyledContainer ref={lottieContainerRef}>
			{globalPerks.map(({ title, text }, index) => (
				<StyledSectionItem
					onMouseOver={playAnimation(index)}
					onMouseLeave={stopAnimation(index)}
					onTouchStart={playAnimation(index)}
					key={index}
				>
					<StyledLogo
						$color={WHY_HEADOUT_LIST_DATA[index].color}
						role='none'
					/>
					<StyledTitle className={'block'}>{title}</StyledTitle>
					<StyledText className={'block'}>{text}</StyledText>
					<StyledBgBlob $color={WHY_HEADOUT_LIST_DATA[index].color} />
				</StyledSectionItem>
			))}
		</StyledContainer>
	);
};

export default memo(WhyHeadoutSection);
