import { RichText } from 'prismic-reactjs';

import embedStructuredData from 'Components/common/embedStructuredData';

import {
	getBreadcrumbsForCollectionPage,
	getBreadCrumbsForFilterViewPage,
} from 'Utils/collectionsUtils';
import { sanitiseURLProtocol } from 'Utils/imageUtils';
import PlatformUtils from 'Utils/platformUtils';
import {
	getCategoriesInfoById,
	getProductCard,
	getProductCardV2,
} from 'Utils/stateUtils';
import {
	getApiString,
	getBaseUrl,
	getHostName,
	getSEOLanguageLabel,
	isHomePageUrl,
} from 'Utils/urlUtils';

import {
	FILTER_VIEW_PAGE_TYPE,
	OPENGRAPH_VIDEO_URL,
	SEO_INDEXABLE_LANGUAGE_CODES,
} from 'Constants/constants';
import { ORGANIZATION, WEBSITE } from 'Constants/schema';

export const getHrefLangTags = (path: string) => {
	const tags = SEO_INDEXABLE_LANGUAGE_CODES.map(lang => {
		return {
			rel: 'alternate',
			hreflang: getSEOLanguageLabel(lang),
			href: `${getBaseUrl()}${
				lang.toLowerCase() !== 'en' ? `/${lang}` : ``
			}${path}`,
		};
	});
	tags.push({
		rel: 'alternate',
		hreflang: 'x-default',
		href: `${getBaseUrl()}${path}`,
	});
	return tags;
};

export const getSchemaMetaData = (pathname: string) => {
	const org = {
		'@context': 'http://www.schema.org',
		'@type': 'Organization',
		name: ORGANIZATION.NAME,
		url: ORGANIZATION.URL,
		logo: ORGANIZATION.LOGO,
		image: ORGANIZATION.IMAGE,
		description: ORGANIZATION.DESCRIPTION,
		address: {
			'@type': 'PostalAddress',
			streetAddress: ORGANIZATION.ADDRESS.STREET_ADDRESS,
			addressLocality: ORGANIZATION.ADDRESS.ADDRESS_LOCALITY,
			addressRegion: ORGANIZATION.ADDRESS.ADDRESS_REGION,
			postalCode: ORGANIZATION.ADDRESS.POSTAL_CODE,
			addressCountry: ORGANIZATION.ADDRESS.ADDRESS_COUNTRY,
		},
		email: ORGANIZATION.EMAIL,
		telephone: ORGANIZATION.CONTACT_POINT.TELEPHONE,
		sameAs: ORGANIZATION.SAME_AS,
	};
	const website = {
		'@context': 'http://www.schema.org',
		'@type': 'WebSite',
		name: WEBSITE.NAME,
		url: WEBSITE.URL,
	};
	if (isHomePageUrl(pathname)) {
		(website as any).potentialAction = {
			'@type': 'SearchAction',
			target: WEBSITE.POTENTIAL_ACTION.TARGET,
			'query-input': WEBSITE.POTENTIAL_ACTION.QUERY_INPUT,
		};
		return [org, website];
	}
	return [website];
};

export const getListPageScriptMeta = ({
	store,
	lang = 'en',
	listPageInfo,
	pageType,
	localizedStrings,
}: any) => {
	let state = store.getState();
	const cities = state.productList;
	const apiLang = getApiString(lang);
	let itemList = {
		'@context': 'http://www.schema.org',
		'@type': 'ItemList',
		itemListElement: [],
	};
	Object.keys(cities).forEach(city => {
		Object.keys(cities[city]).forEach(listSelectorId => {
			let productIdList = cities[city][listSelectorId]['productIdList'];
			(itemList as any).numberOfItems = productIdList.length;
			productIdList.map((productId: any, index: any) => {
				// @ts-expect-error
				itemList.itemListElement.push({
					'@type': 'ListItem',
					position: index + 1,
					url: listSelectorId.includes('pf-')
						? getProductCardV2(state, productId).urlSlug
						: getProductCard(state, productId).urlSlugs[apiLang],
				});
			});
		});
	});
	const city = state?.city;
	const currentCityCode = city?.currentCityCode;
	const breadcrumbData = {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
	};
	if (currentCityCode) {
		let breadcrumbs = {};
		const currentCity = city.citiesMap[currentCityCode];
		const {
			id: cityId,
			displayName: cityDisplayName,
			cityCode,
			urlSlugs: cityUrlSlugs,
		} = currentCity;
		switch (pageType) {
			case FILTER_VIEW_PAGE_TYPE.CATEGORY:
			case FILTER_VIEW_PAGE_TYPE.SUB_CATEGORY:
			case FILTER_VIEW_PAGE_TYPE.PERSONA:
			case FILTER_VIEW_PAGE_TYPE.TOUR_LIST_PAGE: {
				const { categoryId, name, heading } = listPageInfo ?? {};
				const {
					displayName: categoryDisplayName,
					urlSlugs: categoryUrlSlugs,
					heading: categoryHeading,
				} = categoryId
					? getCategoriesInfoById(state, categoryId, cityCode)
					: {};
				breadcrumbs = getBreadCrumbsForFilterViewPage({
					lang,
					pageType,
					cityDisplayName,
					cityUrlSlugs,
					categoryId,
					cityId,
					categoryUrlSlugs,
					categoryDisplayName,
					cityCode,
					name,
					categoryHeading,
					heading,
					localizedStrings,
				});
				break;
			}
			default: {
				breadcrumbs = getBreadcrumbsForCollectionPage({
					collection: listPageInfo,
					city: currentCity,
					lang,
					localizedStrings,
				});
				break;
			}
		}
		// @ts-expect-error TS(2339): Property 'linkTexts' does not exist on type '{}'.
		const { linkTexts = [], linkUrls = [] } = breadcrumbs ?? {};
		(breadcrumbData as any).itemListElement = linkTexts.map(
			(_: any, index: any) => ({
				'@type': 'ListItem',
				name: linkTexts[index],
				position: index + 1,
				...(linkUrls[index] && {
					item: getHostName(linkUrls[index])
						? linkUrls[index]
						: `${getBaseUrl()}${linkUrls[index]}`,
				}),
			}),
		);
	}
	const jsonLDData = [itemList];
	// @ts-expect-error TS(2345): Argument of type '{ '@context': string; '@type': s... Remove this comment to see the full error message
	if (currentCityCode) jsonLDData.push(breadcrumbData);
	return embedStructuredData({
		id: 'productListPageSchemaScript',
		type: 'application/ld+json',
		scriptAsString: JSON.stringify(jsonLDData),
	});
};

export const getCollectionRatingsScriptMeta = ({ collection }: any) => {
	const {
		id,
		displayName,
		heroImageUrl,
		cardImageUrl,
		metaDescription,
		ratingsInfo,
		startingPrice,
	} = collection;
	const { ratingsCount, averageRating } = ratingsInfo ?? {};
	const { listingPrice, currency } = startingPrice ?? {};
	const itemList = {
		'@context': 'https://schema.org/',
		'@type': 'Product',
		name: displayName,
		image: [
			sanitiseURLProtocol(heroImageUrl) ||
				sanitiseURLProtocol(cardImageUrl),
		],
		description: metaDescription,
		sku: String(id),
		productID: String(id),
		aggregateRating: {
			'@type': 'AggregateRating',
			ratingValue: averageRating.toPrecision(2),
			bestRating: 5,
			reviewCount: ratingsCount,
		},
		offers: {
			'@type': 'AggregateOffer',
			availability: 'https://schema.org/InStock',
			lowPrice: String(listingPrice),
			priceCurrency: currency,
		},
	};
	return embedStructuredData({
		id: 'collectionRatingsSchemaScript',
		type: 'application/ld+json',
		scriptAsString: JSON.stringify(itemList),
	});
};

export const getFaqSlicesScriptMeta = ({ slices }: any) => {
	const faqItems = slices.reduce(
		(acc: any, slice: any) => acc.concat(slice?.items ?? []),
		[],
	);
	const itemList = {
		'@context': 'https://schema.org',
		'@type': 'FAQPage',
		mainEntity: faqItems.map((item: any) => ({
			'@type': 'Question',
			name: item?.question,
			acceptedAnswer: {
				'@type': 'Answer',
				text: RichText.asText(item?.answer),
			},
		})),
	};
	return embedStructuredData({
		id: 'faqSlicesMetadataScript',
		type: 'application/ld+json',
		scriptAsString: JSON.stringify(itemList),
	});
};

export const getBannerImage = ({
	imageUrl,
	width,
	desktopImageHeight,
	mobileImageHeight,
}: {
	imageUrl: string;
	width?: string | number;
	desktopImageHeight?: string | number;
	mobileImageHeight?: string | number;
}) => {
	if (!width || !desktopImageHeight || !mobileImageHeight || !imageUrl)
		return {};
	const isDesktop = PlatformUtils.isDesktop();
	const height = isDesktop ? desktopImageHeight : mobileImageHeight;
	return {
		imageUrl: sanitiseURLProtocol(
			imageUrl,
			`auto=compress&w=${width}&h=${height}&fit=min`,
		),
		width,
		height,
	};
};

export const getTwitterCardAndOGImageMetaTags = ({
	imageData,
	title,
	url,
}: any) => {
	return [
		'<meta property="og:type" content="website">',
		`<meta property="og:title" content="${title ?? 'Headout'}"/>`,
		`<meta property="og:url" content="${url}">`,
		'<meta property="og:video:type" content="video/mp4">',
		`<meta property="og:video" content="${OPENGRAPH_VIDEO_URL}">`,
		`<meta property="og:video:url" content="${OPENGRAPH_VIDEO_URL}">`,
		`<meta property="og:video:secure_url" content="${OPENGRAPH_VIDEO_URL}">`,
		`<meta property="og:image" content="${imageData.imageUrl}"/>`,
		`<meta property="og:image:alt" content="${title}"/>`,
		`<meta property="og:image:width" content="${imageData.width}"/>`,
		`<meta property="og:image:height" content="${imageData.height}"/>`,
		`<meta property="og:image:url" content="${imageData.imageUrl}"/>`,
		`<meta property="og:image:secure_url" content="${imageData.imageUrl}"/>`,
		'<meta property="twitter:card" content="summary_large_image"/>',
		`<meta property="twitter:image" content="${imageData.imageUrl}"/>`,
		`<meta property="twitter:title" content="${title ?? 'Headout'}"/>`,
		'<meta property="twitter:site" content="@headout"/>',
		`<meta property="twitter:url" content="${url}">`,
	];
};

export type VideoMetaInfo = {
	url: string;
	name: string;
	description: string;
	thumbnailUrl: string;
	duration: number;
	uploadDate: string;
};

export const getVideoScriptMeta = (videoInfo: VideoMetaInfo) => {
	const { name, description, thumbnailUrl, url, duration, uploadDate } =
		videoInfo;
	const formattedVideoDuration = `PT${duration}S`;
	const formattedUploadDate = new Date(uploadDate).toISOString();

	const itemList = {
		'@context': 'https://schema.org',
		'@type': 'VideoObject',
		name,
		description,
		thumbnailUrl,
		uploadDate: formattedUploadDate,
		duration: formattedVideoDuration,
		contentUrl: url,
	};
	return embedStructuredData({
		id: 'videoScriptMetadata',
		type: 'application/ld+json',
		scriptAsString: JSON.stringify(itemList),
	});
};
